@import "../../../../assets/css/variables";
// @import "../../../../assets/css/rootui-parts/sign";

.rui-sign-form {
  .svg-inline--fa.rui-icon {
    width: 1em;
    min-width: 1em;
    height: 1em;
  }
}

.rui-router-transition-exit-active > .rui-sign {
  min-height: calc(100vh - 20px);
  padding-top: 20px;
}

// Settings
$global-font-size: 14px;
$global-line-height: 1.8;
$global-bg-color: white;

@mixin read-more(
  $rows: 4,
  $font-size: $global-font-size,
  $line-height: $global-line-height,
  $bg-color: $global-bg-color,
  $trigger-color: #2980b9,
  $animation: 500ms ease,
  $el: (
    content: ".content",
    trigger: ".trigger",
    expander: ".expanded",
  ),
  $hover: false
) {
  // Optimal height based on your font size and line-height
  $height: (($font-size * $line-height) * $rows);
  @if map-get($el, trigger) != null {
    $height: $height + ($font-size * $line-height); // + 1 for trigger
  }

  @if $hover {
    @if map-get($el, content) {
      #{map-get($el, content)} {
        max-height: $height;
        &::before {
          height: $font-size + ($font-size * 2);
        }
      }
    }
  } @else {
    position: relative;
    text-decoration: none;
    line-height: $line-height;
    cursor: pointer;

    @if map-get($el, trigger) {
      cursor: text;
      #{map-get($el, trigger)} {
        //display: block;
        //position: absolute;
        bottom: 0;
        cursor: pointer;
        color: $trigger-color;
        font-weight: bold;
      }
    }

    @if map-get($el, content) {
      #{map-get($el, content)} {
        position: relative;
        overflow: hidden;
        max-height: $height;
        transition: max-height $animation;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: $font-size + ($font-size * 1.5);
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba($bg-color, 1) 50%,
            rgba($bg-color, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          @if map-get($el, expander) != null {
            transition:
              opactiy $animation,
              visibility $animation;
          }
        }
      }
    }

    @if map-get($el, expander) {
      &#{map-get($el, expander)} {
        #{map-get($el, content)}::before,
        #{map-get($el, trigger)} {
          opacity: 0;
          visibility: hidden;
        }
        #{map-get($el, content)} {
          max-height: 600px; // great value for height animation
        }
      }
    }
  }
}

.wrap {
  max-width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 0;
  &::before,
  &::after {
    content: " ";
    display: table;
  }
  > * {
    display: block;
    float: left;
    height: auto;
    padding: 1rem 0rem;

    width: 100%;
  }
}

// Setup
.read-more {
  @include read-more();
}

.no-trigger {
  @include read-more(
    $el: (
      content: ".content",
      expander: ".expanded",
    ),
    $hover: true
  );
}

.large {
  $font-size: ($global-font-size * 1.1);
  font-size: $font-size;
  @include read-more($rows: 3, $font-size: $font-size, $hover: true);
}

.react-datepicker-wrapper {
  width: 100%;
}

.height-logo {
  height: 20rem;
}

.dark-font {
  color: $color_dark_8;
}

@media screen and (min-width: 768px) {
  .height-logo {
    height: 25rem;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.box-sold-out {
  width: 50%;
  text-align: center;
  border: 1px solid #dc2f02;
  border-radius: 2px;
  margin-top: 3rem;
  padding: 2.5px 20px;
}

.width-ticket-75 {
  width: 75% !important;
  @media screen and (max-width: 396px) {
    width: 65% !important;
  }
}
.width-ticket-25 {
  width: 25% !important;
  @media screen and (max-width: 396px) {
    width: 35% !important;
  }
}

.minh-none {
  min-height: unset !important;
}

.pe-none {
  pointer-events: none !important;
}

// .pb-11 {
//   padding-bottom: 11px !important;
// }

.font-size-1-1rem {
  font-size: 1.1rem !important;
}

.read-more-redirect {
  position: relative;
  overflow: hidden;
  max-height: 180px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
}

.read-more-redirect::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
}

.w-child-100 > img {
  width: 100% !important;
}

.border-dashed {
  border-style: dashed;
}

.w-70 {
  width: 70% !important;
}

// .shopping-cart {
//   width: 22px;
//   height: 22px;
//   cursor: pointer !important;
// }

// .top-main-content {
//   width: 400px;
//   background:white;
//   padding: 15px 38px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   h3 {
//     margin: 0;
//   }
// }

// @media (max-width: 575px) {
//   .top-main-content {
//     width: 100vw;
//   }
// }

// .cart-notif-total {
//   position: absolute;
//   width: 18px;
//   height: 18px;
//   border-radius: 50%;
//   background: #197BFF;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #FFF;
// }

.view-cart {
  font-size: 0.95rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

.min-height-one-click {
  min-height: 100px;
}

.product-cover {
  max-width: 400px;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-top: 20px;
}

@media screen and (max-width: 500px) {
  .product-cover {
    max-width: 100%;
    border-radius: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  .min-height-one-click {
    min-height: 100px;
  }
  .checkout-form {
    font-size: 14.3px !important;
    max-width: 100% !important;
  }

  .pay-button {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 14.95px;
  }
}
